import React, {useState} from 'react';

const checkAuthStatus = () => {
    const token = localStorage.getItem('idToken');
    if (!token) {
        return false;
    } else {
        const expirationDate = new Date(localStorage.getItem('expirationDate'));
        return expirationDate >= new Date();
    }
};

export const AuthContext = React.createContext({
    isAuth: false,
    login: () => {
    }
});

const AuthContextProvider = (props) => {
    const [isAuthenticated, setIsAuthenticated] = useState(checkAuthStatus());

    const setAuthenticated = (value) => {
        setIsAuthenticated(value);
    };

    return (
        <AuthContext.Provider value={{setAuthenticated: setAuthenticated, isAuth: isAuthenticated}}>
            {props.children}
        </AuthContext.Provider>
    );
};

export default AuthContextProvider;
