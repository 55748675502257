import React, {useState} from 'react';
import styles from './CustomSelect.module.css';
// import styles from "../TabContent/TabContent.module.css";

const CustomSelect = (props) => {

    const onClick = (value) => {
        props.onChange(value);
        setOpen(false);
    };
    let label;
    const options = props.options.map(
        item => {
            let active;
            if (item.value === props.value) {
                active = styles.active;
                label = item.label;
            }
            return (
                <div
                    key={item.value}
                    className={`${styles.option} ${active}`}
                    onClick={() => onClick(item.value)}>
                    {item.label}
                </div>
            )
        });

    const [open, setOpen] = useState(false);

    const menu = open ? (
        <div className={styles.menu}>
            {options}
        </div>
    ) : null;

    return (
        <div className={styles.selectContainer}>
            <div className={styles.selectControl} onClick={() => setOpen(!open)}>
                <div className={styles.selectedValueContainer}>
                    <div className={styles.selectedValue}>{label}</div>
                </div>
                <div className={styles.indicators}>
                    <div className={styles.indicator}>
                        <i className={styles.arrowDown}/>
                    </div>
                </div>
            </div>
            {menu}
        </div>
    );
};

export default CustomSelect;

