import React from 'react';
import PropTypes from 'prop-types';
import styles from './TabHeader.module.css';

const TabHeader = (props) => {
    return (
        <div className={styles.tab}>
            {
                props.tabs.map((tab, index) => {
                    return <button
                        key={tab}
                        onClick={() => props.clicked(index)}
                        className={index === props.activeIndex ? styles.active : null}>
                        {tab}
                    </button>;
                })
            }
        </div>
    );
};

TabHeader.propTypes = {
    label: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    activeIndex: PropTypes.number,
    clicked: PropTypes.func
};

export default React.memo(TabHeader, (prevProps, nextProps) => prevProps.activeIndex === nextProps.activeIndex);
