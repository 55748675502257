import React from 'react';
import styles from './TabContent.module.css';
import CustomInput from "../CustomInput/CustomInput";
import classes from "../CustomInput/CustomInput.module.css";
import CustomSelect from "../CustomSelect/CustomSelect";

const TabContent = (props) => {
    const {activeIndex, currencies, title, values, onInputChange, refs} = props;

    // remove the decimal points
    const amount = values.amount ? Math.round(values.amount) : '';
    const amountPlusFee = values.amountPlusFee ? Math.round(values.amountPlusFee) : '';
    const toBeReceived = values.toBeReceived ? Math.round(values.toBeReceived) : '';
    const fee = values.fee ? Math.round(values.fee) : '';

    const options = [
        {value: "code_agent", label: 'Code Agent'},
        {value: "cash", label: 'Cash'}
    ];
    
    const formContent = activeIndex === 0 ? (
        <form action="#">
            <div className={'row'}>
                <div className={styles.col25}>
                    <label htmlFor="method" className={classes.label}>Mode de paiement</label>
                </div>
                <CustomSelect value={props.paymentMethod} onChange={props.onPaymentMethodChange} options={options}/>
            </div>
            <CustomInput
                inputRef={refs.amount}
                id={'amount'}
                changed={onInputChange}
                value={amount}
                label={"Montant à envoyer"}
                suffix={currencies[0]}
                // placeholder={"Montant à envoyer"}
            />
            <CustomInput
                id={'fee'}
                disabled
                inputRef={refs.fee}
                changed={onInputChange}
                value={fee}
                label={"Frais"}
                suffix={currencies[0]}
                // placeholder={"Frais"}
            />
            <CustomInput
                inputRef={refs.amountPlusFee}
                id={'amountPlusFee'}
                changed={onInputChange}
                value={amountPlusFee}
                label={"Montant frais inclus"}
                suffix={currencies[0]}
                // placeholder={"Montant frais inclus"}
            />
            <CustomInput

                inputRef={refs.toBeReceived}
                disabled
                id={'toBeReceived'}
                changed={onInputChange}
                value={toBeReceived}
                label={"Montant à recevoir"}
                suffix={currencies[1]}
                // placeholder={"Cash"}
            />
        </form>
    ) : (
        <form action="#">
            <CustomInput
                inputRef={refs.amount}
                id={'amount'}
                changed={onInputChange}
                value={amount}
                label={"Montant à envoyer"}
                suffix={currencies[1]}
                // placeholder={"Montant à envoyer"}
            />
            <CustomInput
                id={'fee'}
                inputRef={refs.fee}
                disabled
                changed={onInputChange}
                value={fee}
                label={"Frais"}
                suffix={currencies[1]}
                // placeholder={"Frais"}
            />
            <CustomInput
                inputRef={refs.amountPlusFee}
                id={'amountPlusFee'}
                changed={onInputChange}
                value={amountPlusFee}
                label={"Montant frais inclus"}
                suffix={currencies[1]}
                // placeholder={"Montant frais inclus"}
            />

            <b>Montant à recevoir:</b>

            <CustomInput
                inputRef={refs.cash}
                id={'cash'}
                changed={onInputChange}
                value={values.cash ? values.cash.toFixed(0) : ''}
                label={"Cash"}
                suffix={currencies[0]}
                // placeholder={"Cash"}
            />
            <CustomInput
                inputRef={refs.codeAgent}
                id={'codeAgent'}
                changed={onInputChange}
                value={values.codeAgent ? values.codeAgent.toFixed(0) : ''}
                label={"Par Airtel Money (après retrait)"}
                suffix={currencies[0]}
                disabled
                // placeholder={"Code Agent"}
            />
            
        </form>
    );

    return (

        <div key={title} className={`${styles.tabContent} container`} style={{display: 'block'}}>
            {formContent}
        </div>

    );
};

// TabContent.propTypes = {
//     activeIndex: PropTypes.number,
//     currencies: PropTypes.arrayOf(PropTypes.string),
//     title: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
// };

// export default TabContent;
export default React.memo(TabContent, (prevProps, nextProps) =>
    prevProps.activeIndex === nextProps.activeIndex &&
    prevProps.paymentMethod === nextProps.paymentMethod &&
    prevProps.values === nextProps.values
);
