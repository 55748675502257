import React, {useContext} from 'react';
import './NavigationBar.css';
import {Link, NavLink} from "react-router-dom";
import {AuthContext} from "../../context/auth-context";
import Logo from '../../assets/logo-white.png';

const NavigationBar = (props) => {
    const auth = useContext(AuthContext);

    let routers;
    if (auth.isAuth) {
        routers = [
            <li key={'manager'} className="nav-item" onClick={props.onToggleClicked}>
                <NavLink activeClassName={'active'} className={'nav-link'} exact to={'/manager'}>Manager</NavLink>
            </li>,
            <li key={'logout'} className="nav-item login" onClick={props.onLogout}>
                <a className="nav-link " href="#">Se déconnecter</a>
            </li>
        ]
    } else {
        routers =
            <li onClick={props.onLoginClicked} className="nav-item login"><a className="nav-link " href="#">Se
                connecter</a>
            </li>
    }

    const show = props.expand ? 'show' : null;

    return (
        <nav className="mb-1 navbar navbar-expand-lg navbar-dark default-color">
            {/*<Link to={'/'} className={'navbar-brand'}><img alt={"Logo"} src={Logo} /></Link>*/}
            <Link to={'/'} className={' navbar-brand '}><img src={Logo}/></Link>
            <button className="navbar-toggler" onClick={props.onToggleClicked}>
                <span className="navbar-toggler-icon"> </span>
            </button>
            <div className={`collapse navbar-collapse ${show}`} id="navbarSupportedContent-3">
                <ul className="navbar-nav ">
                    <li className="nav-item" onClick={props.onToggleClicked}>
                        <NavLink activeClassName={'active'} className={'nav-link'} exact to={'/'}>Conversion</NavLink>
                    </li>
                    {routers}
                </ul>
            </div>
        </nav>
    )
};

export default NavigationBar;

