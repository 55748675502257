import * as actionTypes from "../actions/actionTypes";
import {updateObject} from "../../utils/utility";


const initialState = {
    error: null,
    loading: false,
    ratesAndFees: {
        gabToChinaFee: '',
        gabToChinaRate: '',
        chinaToGabonFee: '',
        chinaToGabRate: ''
    }
};

function loading(state, action) {
    return updateObject(state, {error: null, loading: true});
}

function loadRatesSuccess(state, action) {
    return updateObject(state, {
        ratesAndFees: action.ratesAndFees,
        error: action.error,
        loading: action.loading,
    });
}

function updateRatesSuccess(state, action) {
    const ratesAndFees = updateObject(state.ratesAndFees, action.data);

    return updateObject(state, {
        ratesAndFees: ratesAndFees,
        error: null,
        loading: false,
    });
}

function updateRatesFail(state, action) {
    return updateObject(state, {
        error: action.error,
        loading: false,
    });
}

function loadRatesFail(state, action) {
    return updateObject(state, {
        error: action.error,
        loading: false,
    });
}

const clearError = (state, action) => {
    return updateObject(state, {
        error: null,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CONVERSION_LOADING:
            return loading(state, action);
        case actionTypes.LOADING_RATES_SUCCESS:
            return loadRatesSuccess(state, action);
        case actionTypes.UPDATE_RATES_SUCCESS:
            return updateRatesSuccess(state, action);
        case actionTypes.UPDATE_RATES_FAIL:
            return updateRatesFail(state, action);
        case actionTypes.LOADING_RATES_FAIL:
            return loadRatesFail(state, action);
        case actionTypes.CONVERSION_CLEAR_ERROR:
            return clearError(state, action);
    }
    return state;
};

export default reducer;

