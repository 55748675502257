import React from 'react';
import '../../hoc/Layout/Layout.css';

const Content = (props) => {

    return (
        <main className={'content'}>
            {props.children}
        </main>
    );
};

// export default Content;
export default React.memo(Content, (prevProps, nextProps) => prevProps.children === nextProps.children);
