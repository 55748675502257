import React from 'react';
import NavigationBar from "../../components/NavigationBar/NavigationBar";
import Content from "../../components/Content/Content";
import Modal from "../../components/Modal/Modal";
import Login from "../../components/Login/Login";
import {Redirect} from "react-router-dom";
import Spinner from "../../components/Spinner/Spinner";
import {AuthContext} from "../../context/auth-context";
import * as actions from "../../store/actions";
import {connect} from "react-redux";
import Footer from "../../components/Footer/Footer";


class Layout extends React.Component {
    static contextType = AuthContext;
    state = {
        isModalVisible: false,
        rememberMe: false,
        // email: 'test@test.com',
        // password: '123456',
        email: '',
        password: '',
        navVisible: false
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {loading, token} = this.props;
        if (!loading && !this.context.isAuth) {
            if (token) {
                this.context.setAuthenticated(true);
                this.setState({
                    isModalVisible: false,
                    email: '',
                    password: ''
                });
            }
        }
    }

    onInputChangeHandler = (event) => {
        const {name, value} = event.currentTarget;
        this.setState({[name]: value})
    };
    onSubmitHandler = (event) => {
        event.preventDefault();
        this.props.onAuth(
            this.state.email,
            this.state.password,
            this.state.rememberMe
        );
    };
    onModalClosed = () => {
        if (this.props.error) {
            this.props.authClearError();
        } else {
            this.setState(prevState => {
                return {
                    isModalVisible: !prevState.isModalVisible,
                    navVisible: false
                }
            });
        }
    };
    onLogout = () => {
        this.props.onLogout();
        this.context.setAuthenticated(false);
        this.onToggleClickedHandler();
    };
    onRememberMeChangeHandler = (event) => {
        this.setState(prevState => {
            return {rememberMe: !prevState.rememberMe}
        })
    };
    onToggleClickedHandler = () => {
        this.setState(state => {
            return {
                navVisible: !state.navVisible
            }
        })
    };

    render() {
        const redirect = this.context.isAuth ? <Redirect to="/manager"/> : null;
        let content = <Spinner/>;
        if (!this.props.loading) {
            const {error} = this.props;
            content = (
                <>
                    {redirect}
                    <Modal fromLogin={true} header={error ? "Error" : null} show={this.state.isModalVisible}
                           modalClosed={this.onModalClosed}>
                        {error ? <p style={{textAlign: 'center'}}>{error}</p> : (
                            <Login
                                rememberMe={this.state.rememberMe}
                                checked={this.onRememberMeChangeHandler}
                                email={this.state.email}
                                password={this.state.password}
                                onSubmitHandler={this.onSubmitHandler}
                                changed={this.onInputChangeHandler}
                            />
                        )}
                    </Modal>
                    <NavigationBar
                        expand={this.state.navVisible}
                        onToggleClicked={this.onToggleClickedHandler}
                        onLogout={this.onLogout}
                        onLoginClicked={this.onModalClosed}/>
                    <Content>{this.props.children}</Content>
                    <Footer/>
                </>
            );
        }

        return content;
    }
}


const mapStateToProps = state => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        token: state.auth.token
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onAuth: (email, password, rememberMe) => dispatch(actions.auth(email, password, rememberMe)),
        onLogout: () => dispatch(actions.logout()),
        authClearError: () => dispatch(actions.authClearError())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
// export default React.memo(withUseHttp(Layout), (prevProps, nextProps) => prevProps.children === nextProps.children);
