import React from 'react';
import styles from './Contacts.module.css'

import QrCode from '../../assets/qr-code.png';
import Contact from '../../assets/contact.png';


const Contacts = ()=>{
    return(

        <div className={styles.tabContent}>
            <img alt={'Qr Code'} src={QrCode}/>
            <img alt={"What's up Contact"} src={Contact}/>
        </div>

    )
};

export default Contacts;
