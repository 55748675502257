import React from 'react';
import Backdrop from "../Backdrop/Backdrop";
import styles from "./Modal.module.css";

const Modal = props => {

    const header = props.header ? (
        <div className={styles.modalHeader}>
            <h1 className={styles.modalTitle}> {props.header} </h1>
            <button className={styles.close} onClick={props.modalClosed}>×</button>
        </div>
    ) : null;

    return <React.Fragment>
        <Backdrop show={props.show}/>
        {props.show ? <div className={`${styles.modal} show ${styles.animate}}`}>
            <div className={styles.modalDialog}>
                <div className={styles.modalContent}>
                    {header}
                    <div className={styles.modalBody}>
                        {props.children}
                    </div>

                    <div className={styles.modalFooter}>
                        <button className={props.fromLogin === true ? `notvisiblebtn` : `button validatebtn`}
                                name="confirm" onClick={props.validation}>Valider
                        </button>
                        <button onClick={props.modalClosed} className={`button cancelbtn`}>Annuler</button>
                    </div>

                </div>
            </div>
        </div> : null}
    </React.Fragment>
};

export default React.memo(Modal, (prevProps, nextProps) =>
    nextProps.show === prevProps.show &&
    nextProps.children === prevProps.children
);
