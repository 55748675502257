import React from 'react';
import styles from "../TabContent/TabContent.module.css";
import classes from './CustomInput.module.css';

const CustomInput = props => {
    const {id, label, placeholder, suffix, value, changed, type, disabled, inputRef} = props;

    const inputSuffix = suffix ? <span className={classes.suffix}> {suffix} </span> : null;
    const labelComponent = label ? (
        <label className={classes.label} htmlFor={label}>{label}</label>
    ) : null;
    return (
        <div className={'row'}>
            {labelComponent}
            <div className={styles.col100}>
                <input
                    className={classes.input}
                    ref={inputRef}
                    disabled={disabled}
                    type={type ? type : "number"}
                    value={value}
                    onChange={changed}
                    id={id}
                    placeholder={placeholder}
                />
                {inputSuffix}
            </div>
        </div>
    );
};

// CustomInput.propTypes = {
//     optionalLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
//     optionalSuffix: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
//     optionalPlaceholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
//     changed: Pro
// };

export default React.memo(CustomInput, (prevProps, nextProps) =>
    prevProps.value === nextProps.value &&
    prevProps.disabled === nextProps.disabled &&
    prevProps.suffix === nextProps.suffix
);

// export default CustomInput;
