import React, {useEffect, useReducer, useRef, useState} from 'react';
import TabHeader from "../../components/TabHeader/TabHeader";
import TabContent from "../../components/TabContent/TabContent";
import {updateObject} from "../../utils/utility";
import * as actions from '../../store/actions';


import styles from './Conversion.module.css';
import Spinner from "../../components/Spinner/Spinner";
import Modal from "../../components/Modal/Modal";
import {connect} from "react-redux";
import Contacts from '../../components/contacts/Contacts';

import Logo from '../../assets/logo-transparent.png';


const tabsTitles = ["Gabon vers Chine ", "Chine vers Gabon"/*,"France vers Gabon ","Gabon vers France","Chine vers France ","France vers Chine"*/];
const currencies = ["FCFA", "RMB"/*,"EUR","CNI"*/];


const gabInitValues = {
    amount: '',
    amountPlusFee: '',
    toBeReceived: '',
    fee: ''
};
const chinaInitValues = {
    amount: '',
    amountPlusFee: '',
    codeAgent: '',
    cash: '',
    fee: ''
};

const valueReducer = (values, action) => {
    switch (action.type) {
        case 'ONE':
            return updateObject(values, action.update);
        case 'ALL':
            return updateObject(values, action.values);
        case 'CLEAR-GAB':
            return gabInitValues;
        case 'CLEAR-CH':
            return chinaInitValues;
        default:
            throw new Error('should not get here!');
    }
};

const max1 = 167000;
const max2 = 500000;

const Conversion = (props) => {
    /********************************************************
     *              initialize states and values            *
     ********************************************************/

        // used to control the tabs
    const [activeIndex, setActiveIndex] = useState(0);
    const [flag, setFlag] = useState(false); // used to control when to run the setTimeout in the use effect
    const [modelVisible, setModelVisible] = useState(true);
    const [gabValues, gabDispatch] = useReducer(valueReducer, gabInitValues);
    const [chinaValues, chinaDispatch] = useReducer(valueReducer, chinaInitValues);
    const [paymentMethod, setPaymentMethod] = useState('code_agent');

    const {loading, error, ratesAndFees} = props;

    const gabInputRefs = {
        amount: useRef(),
        amountPlusFee: useRef(),
        fee: useRef(),
        toBeReceived: useRef()
    };
    const chInputRefs = {
        amount: useRef(),
        amountPlusFee: useRef(),
        codeAgent: useRef(),
        fee: useRef(),
        cash: useRef()
    };


    /********************************************************
     *     Function to calculate the fees and rates         *
     ********************************************************/

    /***
     * Calculate the fee applied by the Gabon to China transfer in the Airtel money
     * @param value - the amount
     * @returns {number}
     */
    const getAMFee = (value) => {
        let fee = 0;
        if (value < 167000) {
            fee = 0.07 * value;
        } else if (value <= 500000) {
            fee = value * Number(ratesAndFees.gabToChinaFee);
            /*fee = 5000 + value * Number(ratesAndFees.gabToChinaFee);*/
        }
        return fee;
    };

    // Gabon to China
    const calculateGabonToChinaAmountPlusFeeByAmount = (amount) => {
        let result;
        if (paymentMethod === 'code_agent') {
            if (amount <= 500000) {
                result = amount + getAMFee(amount)
            } else {
                const m = Math.floor(amount / 500000);
                const r = amount % 500000;
                result = amount + getAMFee(500000) * m + getAMFee(r)
            }
        } else {
            result = amount + amount * Number(ratesAndFees.gabToChinaFee);
        }
        return result;
    };
    const calculateGabonToChinaToBeReceivedByAmount = (amount) => amount * Number(ratesAndFees.gabToChinaRate);
    const calculateGabToChinaByAmount = (amount) => {
        const amountPlusFee = calculateGabonToChinaAmountPlusFeeByAmount(amount);
        const toBeReceived = calculateGabonToChinaToBeReceivedByAmount(amount);
        const fee = amountPlusFee - amount;
        return {
            fee: fee,
            amount: amount,
            amountPlusFee: amountPlusFee,
            toBeReceived: toBeReceived
        }
    };
    const reverse = (amountPlusFee) => {
        let amount = 0;
        if (amountPlusFee < 183690) {
            amount = Math.round(amountPlusFee / 1.07)
        } else if (amountPlusFee <= 540000) {
            amount = (amountPlusFee) / (1 + Number(ratesAndFees.gabToChinaFee));
                   /*(amountPlusFee - 5000) / (1 + Number(ratesAndFees.gabToChinaFee));*/
        }
        return amount;
    };
    const calculateGabToChinaByAmountPlusFee = (amountPlusFee) => {
        let amount;
        if (paymentMethod === 'code_agent') {
            if (amountPlusFee <= 540000) {
                amount = reverse(amountPlusFee);
            } else {
                const m = Math.floor(amountPlusFee / 540000);
                const r = amountPlusFee % 540000;
                amount = m * reverse(540000) + reverse(r)
            }
        } else {
            const x = (1 + Number(ratesAndFees.gabToChinaFee));
            amount = Math.round(amountPlusFee / x);
        }
        const toBeReceived = calculateGabonToChinaToBeReceivedByAmount(amount);
        const fee = amountPlusFee - amount;
        return {
            fee: fee,
            amount: amount,
            amountPlusFee: amountPlusFee,
            toBeReceived: toBeReceived
        };
    };
    const calculateGabToChinaByAmountToBeReceived = (toBeReceived) => {
        const amount = toBeReceived / Number(ratesAndFees.gabToChinaRate);
        const amountPlusFee = calculateGabonToChinaAmountPlusFeeByAmount(amount);
        const fee = amountPlusFee - amount;
        return {
            fee: fee,
            amount: amount,
            amountPlusFee: amountPlusFee,
            toBeReceived: toBeReceived
        }
    };
    const calculateGabToChinaByFee = (fee) => {
        const amount = fee / Number(ratesAndFees.gabToChinaFee);
        const amountPlusFee = calculateGabonToChinaAmountPlusFeeByAmount(amount);
        const toBeReceived = calculateGabonToChinaToBeReceivedByAmount(amount);
        return {
            amount: amount,
            amountPlusFee: amountPlusFee,
            toBeReceived: toBeReceived
        }
    };
    const onGabToChinaInputChange = (id, value, newValues) => {
        switch (id) {
            case 'amount':
                newValues = updateObject(newValues, calculateGabToChinaByAmount(value));
                break;
            case 'amountPlusFee':
                newValues = updateObject(newValues, calculateGabToChinaByAmountPlusFee(value));
                break;
            case 'toBeReceived':
                newValues = updateObject(newValues, calculateGabToChinaByAmountToBeReceived(value));
                break;
            case 'fee':
                newValues = updateObject(newValues, calculateGabToChinaByFee(value));
                break;
            default:
                return;
        }

        return newValues;
    };

    // China to Gabon
    const calculateChinaToGabonAmountPlusFeeByAmount = (amount) => amount * (1 + Number(ratesAndFees.chinaToGabonFee));
    const calculateChinaToGabonCashByAmount = (amount) => amount * ratesAndFees.chinaToGabRate;
    const calculateChinaToGabonAmountAMByAmount = (amount) => {
        const r = amount * ratesAndFees.chinaToGabRate;

        if (r <= max1) {
            return r / 1.03;
        } else if (r <= max2) {
            return r - 5000;
        } else {
            const m = Math.floor(r / max2);
            const remain = (r % max2);
            const result = (max2 - 5000) * m;
            if (remain <= max1) {
                return remain / 1.03 + result;
            } else if (remain <= max2) {
                return remain - 5000 + result;
            }
        }
    };
    const calculateChinaToGabonByAmount = (amount) => {
        const amountPlusFee = calculateChinaToGabonAmountPlusFeeByAmount(amount);
        const cash = calculateChinaToGabonCashByAmount(amount);
        const codeAgent = calculateChinaToGabonAmountAMByAmount(amount);
        const fee = amountPlusFee - amount;
        return {
            fee: fee,
            amountPlusFee: amountPlusFee,
            cash: cash,
            codeAgent: codeAgent,

        }
    };
    const calculateChinaToGabonByAmountPlusFee = (amountPlusFee) => {
        const amount = amountPlusFee / (Number(ratesAndFees.chinaToGabonFee) + 1);
        const cash = calculateChinaToGabonCashByAmount(amount);
        const codeAgent = calculateChinaToGabonAmountAMByAmount(amount);
        const fee = amountPlusFee - amount;
        return {
            fee: fee,
            amount: amount,
            cash: cash,
            codeAgent: codeAgent
        }
    };
    const calculateChinaToGabonByCash = (cash) => {
        const amount = cash / ratesAndFees.chinaToGabRate;
        const amountPlusFee = calculateChinaToGabonAmountPlusFeeByAmount(amount);
        const codeAgent = calculateChinaToGabonAmountAMByAmount(amount);
        const fee = amountPlusFee - amount;
        return {
            fee: fee,
            amount: amount,
            amountPlusFee: amountPlusFee,
            codeAgent: codeAgent
        }
    };
    const calculateChinaToGabonToBeReceivedByAM = (aMoney) => {
        // 13527000
        let amount, amountPlusFee, cash, fee;
        if (aMoney <= (max1 * 1.03)) {
            amount = aMoney * 1.03 / ratesAndFees.chinaToGabRate;
        } else if (aMoney <= max2 + 5000) {
            amount = (aMoney + 5000) / ratesAndFees.chinaToGabRate
        } else {

            const m = Math.floor(aMoney / max2);
            const r = (aMoney % max2);
            amount = (max2 + 5000) * m;
            if (r <= (max1 / 1.03)) {
                amount = amount + r * 1.03;
            } else if (r <= max2 + 5000) {
                amount = amount + (r + 5000)
            }
            amount = Math.ceil(amount / ratesAndFees.chinaToGabRate);
            // amount = Math.round(( + 5000) / ratesAndFees.chinaToGabRate * m + x);
        }

        amountPlusFee = calculateChinaToGabonAmountPlusFeeByAmount(amount);
        cash = calculateChinaToGabonCashByAmount(amount);
        fee = amountPlusFee - amount;
        return {
            fee: fee,
            amount: amount,
            amountPlusFee: amountPlusFee,
            cash: cash,
        }
    };
    const calculateChinaToGabonByFee = (fee) => {
        const amount = fee / Number(ratesAndFees.chinaToGabonFee);
        const amountPlusFee = calculateChinaToGabonAmountPlusFeeByAmount(amount);
        const cash = calculateChinaToGabonCashByAmount(amount);
        const codeAgent = calculateChinaToGabonAmountAMByAmount(amount);
        return {
            fee: fee,
            amountPlusFee: amountPlusFee,
            cash: cash,
            codeAgent: codeAgent,
            amount: amount
        }
    };
    const onChinaToGabonInputChange = (id, value, newValues) => {
        switch (id) {
            case 'amount':
                newValues = updateObject(newValues, calculateChinaToGabonByAmount(value));
                break;
            case 'amountPlusFee':
                newValues = updateObject(newValues, calculateChinaToGabonByAmountPlusFee(value));
                break;
            case 'codeAgent':
                newValues = updateObject(newValues, calculateChinaToGabonToBeReceivedByAM(value));
                break;
            case 'cash':
                newValues = updateObject(newValues, calculateChinaToGabonByCash(value));
                break;
            case 'fee':
                newValues = updateObject(newValues, calculateChinaToGabonByFee(value));
                break;
            default:
                return;
        }

        return newValues;
    };


    /********************************************************
     *************           effects           **************
     ********************************************************/
    useEffect(() => {
        if (!props.login)
        // load the initial fees and rates
            props.onLoad();
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            // get the active input
            const id = document.activeElement.id;
            if (activeIndex === 0) {
                let newValues = {...gabValues};
                if (id && gabValues[id] && gabValues[id] === Number(gabInputRefs[id].current.value)) {
                    newValues = onGabToChinaInputChange(id, newValues[id], newValues);
                }
                gabDispatch({type: 'ALL', values: newValues})
            } else {
                let newValues = {...chinaValues};
                if (id && chinaValues[id] && chinaValues[id] === Number(chInputRefs[id].current.value)) {
                    newValues = onChinaToGabonInputChange(id, newValues[id], newValues);
                }
                chinaDispatch({type: 'ALL', values: newValues})
            }
        }, 500);
        return () => clearTimeout(timer);
    }, [flag]); // the flag is used to control the execution of this use effect


    /********************************************************
     *                      DOM events                      *
     ********************************************************/
    const onInputChange = (event) => {
        const id = event.target.id;
        const value = Number(event.target.value);
        if (value) {
            if (activeIndex === 0)
                gabDispatch({type: 'ONE', update: {[id]: value}});
            else
                chinaDispatch({type: 'ONE', update: {[id]: value}})
        } else {
            if (activeIndex === 0)
                gabDispatch({type: 'CLEAR-GAB'});
            else
                chinaDispatch({type: 'CLEAR-CH'});
        }
        setFlag(!flag);
    };
    const onTabClicked = (index) => {
        setActiveIndex(index);
        // gabDispatch({type: 'CLEAR'})
    };
    const onPaymentMethodChange = (value) => {
        setPaymentMethod(value);
        gabDispatch({type: 'CLEAR-GAB'})
    };
    /********************************************************
     *                  components rendering                *
     ********************************************************/
    let content = <Spinner/>;
    if (!loading) {
        content = [
            <div key={'content'} className={`container ${styles.tabContainer}`}>
                <div style={{width: "100%", height: '36px', marginBottom: '16px'}}>
                    <img style={{
                        height: '100%', display: 'block',
                        marginLeft: 'auto',
                        marginRight: 'auto'
                    }} alt={'Logo'} src={Logo}/>
                </div>
                <TabHeader
                    clicked={onTabClicked}
                    tabs={tabsTitles}
                    activeIndex={activeIndex}/>
                <TabContent
                    paymentMethod={paymentMethod}
                    onPaymentMethodChange={onPaymentMethodChange}
                    refs={activeIndex === 0 ? gabInputRefs : chInputRefs}
                    values={activeIndex === 0 ? gabValues : chinaValues}
                    onInputChange={onInputChange}
                    currencies={currencies}
                    activeIndex={activeIndex}
                    titles={tabsTitles[activeIndex]}
                />
                <Contacts/>
            </div>
        ];
        if (error) {
            content.push(
                <Modal
                    header={"Error"}
                    key={'modal'} show={modelVisible}
                    modalClosed={() => setModelVisible(!modelVisible)}>
                    <p style={{textAlign: 'center'}}>{error}</p>
                </Modal>
            )
        }
    }
    return content;
};


const mapStateToProps = state => {
    return {
        loading: state.conversion.loading,
        error: state.conversion.error,
        ratesAndFees: state.conversion.ratesAndFees,
        login: state.auth.loading
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onLoad: () => dispatch(actions.loadRates()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Conversion);
