import React, {Suspense} from 'react';
import './App.css';
import Conversion from "./containers/Conversion/Conversion";
import {Redirect, Route, Switch} from 'react-router-dom';
import Layout from "./hoc/Layout/Layout";

import {AuthContext} from "./context/auth-context";

const Manager = React.lazy(() => import("./containers/Manager/Manager"));

class App extends React.Component {
    static contextType = AuthContext;

    render() {
        const auth = this.context;
        let routes;

        if (auth.isAuth) {
            routes = [
                <Route key={"manager"} path="/manager" component={Manager}/>,
                <Route key={'home'} path="/" exact component={Conversion}/>,
                <Redirect key={'redirect'} to="/"/>
            ]
        } else {
            routes = [<Route key={'home'} path="/" exact component={Conversion}/>, <Redirect key={'redirect'} to="/"/>]
        }

        return (
            <Layout>
                <Suspense fallback={<p>Loading...</p>}>
                    <Switch>{routes}</Switch>
                </Suspense>
            </Layout>
        );
    }
}

export default App;
