import React from 'react';
import styles from './Login.module.css';
import avatar from '../../assets/avatar2.png';


const Login = props => {
    return (
        <>
            <form className={`${styles.modalContent} ${styles.animate} `} onSubmit={props.onSubmitHandler}>
                <div className={styles.imgcontainer}>
                    <img src={avatar} alt="Avatar"
                         className={styles.avatar}/>
                </div>

                <div className={styles.loginBody}>
                    <label htmlFor="email"><b>Email</b></label>
                    <input value={props.email} onChange={props.changed} className={'input'} type="text"
                           placeholder="Enter Username" name="email" required/>
                    <label htmlFor="psw"><b>Mot de passe</b></label>
                    <input value={props.password} onChange={props.changed} className={'input'}
                           type="password"
                           placeholder="Enter Password" name="password" required/>

                    <button className={'button'} type="submit">Se connecter</button>
                    <label>
                        <input type="checkbox" value={props.rememberMe} onChange={props.checked} name="remember"/>
                        Se souvenir de moi
                    </label>
                    {/*<span className={styles.psw}>Forgot <a href="#">password?</a></span>*/}
                </div>
            </form>
        </>
    );
};

Login.propTypes = {};

// export default Login;
export default React.memo(Login, (prevProps, nextProps) =>
    prevProps.rememberMe === nextProps.rememberMe &&
    prevProps.email === nextProps.email &&
    prevProps.password === nextProps.password
);
