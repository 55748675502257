import * as actionTypes from "./actionTypes";

export const clearError = () => {
    return {
        type: actionTypes.CONVERSION_CLEAR_ERROR
    }
};


export const loadingStart = () => {
    return {
        type: actionTypes.CONVERSION_LOADING
    };
};

export const loadRatesSuccess = (values) => {
    return {
        type: actionTypes.LOADING_RATES_SUCCESS,
        ratesAndFees: values
    }
};

export const loadRatesFail = (error) => {
    return {
        type: actionTypes.LOADING_RATES_FAIL,
        error: "Cannot load data",
        loading: false,
    }
};

export const loadRates = () => {
    return dispatch => {
        dispatch(loadingStart());

        fetch('https://react-hooks-update-10b1f.firebaseio.com/fees.json', {
            method: 'GET',
            headers: {'Content-Type': 'application/json'}
        }).then(response => {
            return response.json();
        }).then(data => {
            dispatch(loadRatesSuccess(data));
        }).catch(error => {
            dispatch(loadRatesFail(error.response.message));
        });
    };
};

function updateRatesSuccess(data) {
    return {
        type: actionTypes.UPDATE_RATES_SUCCESS,
        data: data
    };
}

function updateRatesFail(message) {
    return {
        type: actionTypes.UPDATE_RATES_FAIL,
        error: message,
    };
}

export const updateRates = (name, value, token) => {

    return dispatch => {
        dispatch(loadingStart());
        const data = {[name]: value};
        const url = `https://react-hooks-update-10b1f.firebaseio.com/fees/.json?auth=${token}`;
        fetch(url, {
            method: 'PATCH',
            body: JSON.stringify(data),
            headers: {'Content-Type': 'application/json'}
        }).then(response => {
            return response.json();
        }).then(data => {
            dispatch(updateRatesSuccess(data));
        }).catch(error => {
            dispatch(updateRatesFail(error.message));
        });
    };
};
