import * as actionTypes from "../actions/actionTypes";
import {updateObject} from "../../utils/utility";


const initialState = {
    token: null,
    error: null,
    loading: false,
};

const auth = (state, action) => {
    return updateObject(state, updateObject(state, {
        email: action.email,
        password: action.password,
        rememberMe: action.rememberMe
    }));
};
const authStart = (state, action) => {
    return updateObject(state, updateObject(state, {
        loading: true,
        error: null,
        token: null,
    }));
};
const authSuccess = (state, action) => {
    return updateObject(state, updateObject(state, {
        loading: false,
        error: null,
        token: action.token
    }));
};
const authLogout = (state, action) => {
    return updateObject(state, updateObject(state, {
        token: null
    }));
};
const authFail = (state, action) => {
    return updateObject(state, updateObject(state, {
        loading: false,
        error: action.error
    }));
};
const authClearError = (state, action) => {
    return updateObject(state, updateObject(state, {
        error: null
    }));
};

const reducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.AUTH:
            return auth(state, action);
        case actionTypes.AUTH_START:
            return authStart(state, action);
        case actionTypes.AUTH_SUCCESS:
            return authSuccess(state, action);
        case actionTypes.AUTH_LOGOUT:
            return authLogout(state, action);
        case actionTypes.AUTH_FAIL:
            return authFail(state, action);
        case actionTypes.AUTH_CLEAR_ERROR:
            return authClearError(state, action);
    }

    return state;
};

export default reducer;
