import * as actionTypes from "./actionTypes";


export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    };
};

export const authSuccess = (token) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        token: token,
    };
};

export const authFail = (error) => {
    let errorMessage = 'nd';
    switch (error.message) {
        case "INVALID_PASSWORD":
            errorMessage = "The password is invalid";
            break;
        case "EMAIL_NOT_FOUND":
            errorMessage = "Account does not exist";
            break;
        default:
            errorMessage = error.message;
    }
    return {
        type: actionTypes.AUTH_FAIL,
        error: errorMessage
    };
};

export const logout = () => {
    localStorage.removeItem('idToken');
    localStorage.removeItem('expirationDate');
    localStorage.removeItem('userId');
    return {
        type: actionTypes.AUTH_LOGOUT
    }
};

export const authClearError = () => {
    return {
        type: actionTypes.AUTH_CLEAR_ERROR
    }
};

export const auth = (email, password, rememberMe) => {
    return dispatch => {
        dispatch(authStart());
        const authData = {
            email: email,
            password: password,
            returnSecureToken: true
        };
        const url = 'https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyCK9w4bF-Nrr-x6a3Etx_rKjAGlzSeuOYY';

        fetch(url, {
            method: 'POST',
            body: JSON.stringify(authData),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            return response.json();
        }).then(data => {
            if (data.error) {
                dispatch(authFail(data.error));
            } else {
                if (rememberMe) {
                    const expirationDate = new Date(new Date().getTime() + data.expiresIn * 1000);
                    localStorage.setItem('idToken', data.idToken);
                    localStorage.setItem('expirationDate', expirationDate);
                    localStorage.setItem('userId', data.localId);
                }
                dispatch(authSuccess(data.idToken));
            }
        }).catch(error => {
            dispatch(authFail(error));
        });
    };
};
