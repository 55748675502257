export const UPDATE_RATES_FAIL = 'UPDATE_RATES_FAIL';
export const UPDATE_RATES_SUCCESS = 'UPDATE_RATES_SUCCESS';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const LOADING_RATES_FAIL = 'LOADING_RATES_FAIL';
export const LOADING_RATES_SUCCESS = 'LOADING_RATES_SUCCESS';
export const CONVERSION_LOADING = 'LOADING_RATES_INIT';
export const CONVERSION_CLEAR_ERROR = 'CONVERSION_CLEAR_ERROR';


export const AUTH = 'AUTH';
export const AUTH_START = 'AUTH_START';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_CLEAR_ERROR = 'AUTH_CLEAR_ERROR';

