import React from 'react';

const Footer = () => {
    return (
        <footer style={{
            marginTop: "10px",
            padding: "16px",
            textAlign: "center",
            background: "#00A99E",
            color: 'white'

        }}>
            <a>Powered by Schonect Technology</a>
        </footer>
    );
};

export default Footer;
